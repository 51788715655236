import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import Seo from "../components/Seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { User } from "../icons";
import { Trans } from "gatsby-plugin-react-i18next";
const authors = ({ data }) => {
  // get authors array from allAuthorsJson query
  const authors = data.allAuthorsJson.nodes;

  // get array of object from allMarkdownRemark group query which contains author slug and post count
  const postGroup = data.allMarkdownRemark.group;

  // Add post count to each author object
  const AuthorsWithPostCount = authors.map((a) => {
    const author = postGroup.find((el) => el.fieldValue === a.id);
    const count = typeof author !== "undefined" ? author.totalCount : 0;
    return { ...a, postCount: count };
  });

  // sorting and reversing the tags array based on post count
  AuthorsWithPostCount.sort((a, b) => {
    return a.postCount - b.postCount;
  }).reverse();

  return (
    <Layout>
      <Helmet>
  {/* Add hrefLang attribute in the head */}
  <link rel="alternate" hrefLang="en" href="https://staging.quranplayermp3.com/authors/" />
  <link rel="alternate" hrefLang="ar" href="https://staging.quranplayermp3.com/ar/authors/" />
  <link rel="alternate" hrefLang="fr" href="https://staging.quranplayermp3.com/fr/authors/" />
  <link rel="alternate" hrefLang="es" href="https://staging.quranplayermp3.com/es/authors/" />
  <link rel="alternate" hrefLang="da" href="https://staging.quranplayermp3.com/da/authors/" />
  <link rel="alternate" hrefLang="de" href="https://staging.quranplayermp3.com/de/authors/" />
  <link rel="alternate" hrefLang="tr" href="https://staging.quranplayermp3.com/tr/authors/" />
  <link rel="alternate" hrefLang="ur" href="https://staging.quranplayermp3.com/ur/authors/" />
  <link rel="alternate" hrefLang="zh" href="https://staging.quranplayermp3.com/zh/authors/" />
  <link rel="alternate" hrefLang="ru" href="https://staging.quranplayermp3.com/ru/authors/" />
  <link rel="alternate" hrefLang="pt" href="https://staging.quranplayermp3.com/pt/authors/" />
  <link rel="alternate" hrefLang="it" href="https://staging.quranplayermp3.com/it/authors/" />
  <link rel="alternate" hrefLang="id" href="https://staging.quranplayermp3.com/id/authors/" />
  <link rel="alternate" hrefLang="bn" href="https://staging.quranplayermp3.com/bn/authors/" />
  <link rel="alternate" hrefLang="nl" href="https://staging.quranplayermp3.com/nl/authors/" />
  <link rel="alternate" hrefLang="hi" href="https://staging.quranplayermp3.com/hi/authors/" />
  <link rel="alternate" hrefLang="fa" href="https://staging.quranplayermp3.com/fa/authors/" />
  <link rel="alternate" hrefLang="ug" href="https://staging.quranplayermp3.com/ug/authors/" />
  <link rel="alternate" hrefLang="so" href="https://staging.quranplayermp3.com/so/authors/" />
  <link rel="alternate" hrefLang="sw" href="https://staging.quranplayermp3.com/sw/authors/" />
  <link rel="alternate" hreflang="ha" href="https://staging.quranplayermp3.com/ha/all-reciters/" />
  <link rel="alternate" hreflang="bs" href="https://staging.quranplayermp3.com/bs/all-reciters/" />
  <link rel="alternate" hreflang="th" href="https://staging.quranplayermp3.com/th/all-reciters/" />
  <link rel="alternate" hreflang="tg" href="https://staging.quranplayermp3.com/tg/all-reciters/" />
  <link rel="alternate" hreflang="tl" href="https://staging.quranplayermp3.com/tl/all-reciters/" />
  <link rel="alternate" hrefLang="x-default" href="https://staging.quranplayermp3.com/authors/" />
</Helmet>

      <Seo title="Quran MP3 & Nasheed Videos | Authors " />
      <div className="main">
        <div className="container">
          <div className="centered-page-header text-center">
            <h1 className="title">
              <Trans>Authors</Trans>
            </h1>
            {/* <div className="description">
                add description here
            </div> */}
          </div>
          <div className="row">
            {AuthorsWithPostCount.filter((author) => author.postCount > 0).map(
              (author, index) => (
                <div
                  className="col-xl-4 col-lg-6 col-md-6 author-card-wrap"
                  key={index}
                >
                  <Link
                    to={`/author${author.fields.slug}`}
                    className="author-card"
                  >
                    <div className="avatar-wrap">
                      {author.profilePicture !== null ? (
                        <GatsbyImage
                          image={getImage(author.profilePicture)}
                          alt={author.name}
                        />
                      ) : (
                        <div className="avatar no-image">
                          <User />
                        </div>
                      )}
                    </div>
                    <div className="author-info">
                      <h2 className="name h5">
                        <Trans>{author.name}</Trans>
                      </h2>
                      <div className="author-meta">
                        {author.location && (
                          <span className="author-location">
                            <Trans>{author.location}</Trans>
                          </span>
                        )}
                        <span className="post-count">
                          {` `}
                          {author.postCount}
                          {` `}
                          <Trans>{author.postCount > 1 ? "posts" : "post"}</Trans> 
                        </span>
                      </div>
                      {author.description && (
                        <div className="bio">
                          <Trans>{author.description}</Trans>
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default authors;

export const query = graphql`
  query ($language: String!) {
    allAuthorsJson {
      nodes {
        ...AuthorQueryFragment
      }
    }
    allMarkdownRemark(filter: { frontmatter: { published: { ne: false } } }) {
      group(field: frontmatter___author___id) {
        fieldValue
        totalCount
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
